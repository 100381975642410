import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M455 4018 c21 -12 52 -47 72 -82 9 -16 12 -217 12 -846 l1 -825 -23
-40 c-12 -22 -38 -50 -56 -62 l-34 -23 354 0 c264 1 349 3 334 12 -36 20 -67
52 -81 87 -11 27 -14 105 -15 377 l-1 342 23 43 c14 25 40 52 61 64 l36 21
-28 16 c-16 9 -42 35 -59 59 l-31 42 0 354 c0 391 -1 384 65 442 l36 31 -343
0 c-265 -1 -338 -3 -323 -12z"/>
<path d="M1369 3991 c22 -22 45 -57 51 -78 8 -26 10 -147 8 -374 l-3 -336 -27
-41 c-16 -23 -45 -50 -65 -61 -21 -11 -30 -20 -21 -21 25 0 76 -48 98 -91 19
-36 20 -60 19 -371 -1 -383 -3 -394 -83 -456 l-29 -22 354 1 c276 0 348 3 329
12 -34 15 -59 44 -81 92 -18 38 -19 83 -19 845 l0 806 23 44 c12 25 37 55 56
67 l34 23 -342 0 -341 0 39 -39z"/>
<path d="M2058 3663 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2196 3653 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M2758 3646 c-25 -6 -50 -14 -54 -18 -4 -5 -14 -8 -21 -8 -19 0 -183
-71 -183 -79 1 -17 19 -33 31 -26 8 5 5 -1 -5 -13 -11 -12 -24 -22 -30 -22 -6
0 -4 4 4 10 8 5 11 12 8 15 -10 10 -28 -4 -28 -21 0 -8 -4 -12 -10 -9 -6 4 -3
17 7 33 l17 27 -24 -20 c-14 -10 -40 -35 -58 -55 -30 -33 -31 -36 -15 -49 17
-12 16 -13 -5 -8 -17 4 -26 -1 -37 -23 -8 -16 -15 -40 -15 -54 0 -14 -4 -28
-10 -31 -5 -3 -10 -16 -10 -28 l1 -22 19 24 c12 14 20 18 20 9 0 -7 -9 -20
-20 -28 -22 -16 -29 -83 -10 -95 6 -3 10 -14 10 -24 0 -17 44 -114 63 -138 6
-9 15 -10 28 -3 14 8 17 7 13 -4 -3 -8 0 -17 6 -21 6 -4 8 -14 5 -24 -3 -11
10 -33 39 -63 35 -37 49 -45 72 -42 25 3 25 3 6 -4 -22 -7 -19 -11 49 -70 40
-34 78 -62 83 -62 6 0 0 9 -14 20 -21 17 -23 21 -10 30 19 12 27 12 35 0 3 -6
-1 -7 -9 -4 -20 8 -20 -3 -1 -23 9 -8 14 -20 12 -26 -2 -6 12 -22 30 -34 39
-27 40 -27 46 -10 2 6 3 4 2 -5 -2 -10 15 -32 40 -52 33 -28 45 -32 55 -23 10
10 12 8 7 -6 -5 -14 1 -21 28 -33 19 -8 35 -17 35 -20 0 -3 -4 -2 -10 1 -5 3
-10 4 -10 2 0 -2 19 -17 42 -32 83 -55 214 -182 256 -249 16 -25 31 -44 33
-42 2 3 5 -7 6 -21 0 -14 5 -28 11 -32 5 -3 9 -32 8 -63 -1 -41 2 -58 11 -58
7 0 13 -5 13 -11 0 -5 -5 -7 -10 -4 -6 4 -17 -11 -25 -32 -8 -21 -22 -51 -31
-68 -15 -29 -15 -29 3 -13 18 16 19 16 19 0 0 -12 -9 -19 -25 -20 -30 -3 -76
-42 -49 -42 8 0 21 7 28 15 7 8 18 15 24 15 6 -1 0 -10 -13 -21 -14 -11 -33
-18 -43 -16 -10 3 -35 -4 -55 -15 -23 -12 -28 -17 -13 -13 14 4 36 8 50 10 14
2 58 13 99 25 86 24 126 43 71 33 -19 -3 -41 -10 -48 -16 -9 -7 -16 -7 -24 1
-9 9 -6 12 12 12 15 0 20 3 13 8 -6 4 20 7 58 7 39 0 73 5 77 11 4 5 35 27 70
48 61 37 88 66 74 80 -3 4 1 4 11 0 15 -5 17 -1 12 32 -3 24 -1 34 4 25 6 -9
11 -10 15 -2 4 6 0 18 -8 27 -20 19 -20 20 2 20 20 0 26 -43 9 -66 -7 -9 -2
-5 11 8 13 14 21 31 18 38 -3 8 1 14 8 14 18 0 44 67 52 133 7 58 -12 171 -27
162 -5 -3 -6 0 -3 8 12 31 -52 123 -161 233 -113 113 -158 149 -175 139 -6 -3
-7 1 -4 10 4 11 -4 19 -30 30 -20 8 -36 18 -36 21 0 3 5 2 12 -2 7 -4 8 -3 4
4 -4 7 -12 12 -17 12 -9 0 -67 37 -79 51 -19 20 -78 58 -83 52 -4 -3 -7 -2 -7
4 0 6 -24 29 -53 51 -29 22 -67 54 -84 71 -17 17 -37 29 -45 28 -7 -2 -10 -2
-6 0 4 3 -2 18 -14 34 -17 22 -24 26 -35 17 -11 -8 -12 -7 -7 6 4 10 -4 33
-20 57 -15 22 -31 51 -36 64 -5 14 -16 25 -25 25 -8 0 -15 5 -15 11 0 5 5 7
11 4 7 -5 10 10 8 46 -4 105 26 188 83 233 21 16 28 26 18 27 -8 1 -1 5 15 9
23 6 14 8 -35 8 -36 0 -86 -5 -112 -12z m85 -10 c10 -8 14 -17 9 -20 -6 -3
-12 1 -16 9 -3 8 -10 15 -15 15 -7 0 -7 -9 -1 -26 7 -17 7 -32 0 -45 -15 -26
-21 -24 -11 4 5 12 5 31 0 42 -4 11 -8 23 -8 28 -1 11 22 8 42 -7z m-123 -30
c0 -2 -9 -6 -20 -9 -11 -3 -18 -1 -14 4 5 9 34 13 34 5z m-80 -26 c0 -5 -7
-10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z m45 -18 c-3 -3
-11 0 -18 7 -9 10 -8 11 6 5 10 -3 15 -9 12 -12z m-130 -72 c-3 -5 -12 -10
-18 -10 -7 0 -6 4 3 10 19 12 23 12 15 0z m-115 -41 c-7 -11 -14 -18 -17 -15
-8 8 5 36 17 36 7 0 7 -6 0 -21z m307 -16 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18
2 -7 2 -21 0 -30z m-277 2 c0 -8 -4 -15 -10 -15 -5 0 -7 7 -4 15 4 8 8 15 10
15 2 0 4 -7 4 -15z m305 -54 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21
15 7z m-410 -33 c-6 -26 -5 -30 9 -25 8 3 18 1 22 -6 4 -7 4 -9 -1 -5 -4 4
-14 3 -20 -2 -17 -14 -27 13 -19 46 9 36 17 29 9 -8z m382 0 c-3 -7 -5 -2 -5
12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-20 -20 c-3 -8 -6 -5 -6 6 -1 11 2 17 5
13 3 -3 4 -12 1 -19z m99 -111 c-3 -12 2 -29 11 -39 9 -10 11 -18 6 -18 -19 0
-34 27 -31 58 1 17 6 28 11 25 5 -3 6 -15 3 -26z m29 -17 c3 -5 1 -10 -4 -10
-6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-498 -82 c-3 -8 -6 -5 -6
6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m30 -20 c-3 -7 -5 -2 -5 12 0 14 2 19 5
13 2 -7 2 -19 0 -25z m3 -34 c0 -3 -7 -1 -15 6 -8 7 -15 17 -15 22 0 5 7 3 15
-6 8 -8 15 -18 15 -22z m573 19 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19
-2 13 -5z m48 -48 c27 -25 34 -35 22 -34 -17 0 -83 69 -68 69 4 0 25 -16 46
-35z m79 -55 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4
16 -10z m-30 -24 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0
10 -2 10 -4z m-510 -56 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10
10 6 0 10 -4 10 -10z m698 -47 c16 -15 8 -17 -17 -3 -10 5 -22 17 -25 26 -6
15 -4 15 11 3 10 -8 24 -20 31 -26z m-638 -43 c0 -5 -4 -10 -10 -10 -5 0 -10
5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m715 0 c3 -5 1 -10 -4 -10 -6 0
-11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-11 -29 c3 -5 -3 -11 -15 -14
-16 -4 -18 -3 -8 9 13 16 16 17 23 5z m49 -18 c-7 -2 -19 -2 -25 0 -7 3 -2 5
12 5 14 0 19 -2 13 -5z m64 -50 c-8 -7 -47 29 -41 38 3 5 14 -1 25 -12 11 -11
18 -23 16 -26z m-23 -8 c11 -8 17 -18 13 -21 -3 -3 -16 3 -28 15 -26 24 -14
28 15 6z m51 -14 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m55
-56 c0 -3 -9 0 -20 7 -11 7 -20 19 -20 27 0 11 6 9 20 -7 11 -12 20 -24 20
-27z m-646 20 c11 -8 17 -17 14 -20 -3 -3 -16 3 -29 14 -27 23 -14 28 15 6z
m-24 -30 c7 -9 11 -17 8 -20 -2 -2 -12 4 -21 15 -9 11 -13 20 -8 20 5 0 14 -7
21 -15z m630 5 c0 -5 -7 -7 -15 -4 -8 4 -15 8 -15 10 0 2 7 4 15 4 8 0 15 -4
15 -10z m97 -42 c28 -26 30 -43 3 -26 -11 7 -20 18 -20 25 0 6 -6 14 -12 16
-7 3 -9 6 -4 6 6 1 20 -9 33 -21z m-657 8 c0 -7 -40 -14 -44 -7 -3 4 6 8 20 9
13 1 24 0 24 -2z m45 -27 c10 -30 2 -32 -12 -3 -6 13 -7 24 -3 24 5 0 12 -9
15 -21z m-25 -19 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0
11 -4 11 -10z m620 0 c0 -5 -7 -7 -15 -4 -8 4 -15 8 -15 10 0 2 7 4 15 4 8 0
15 -4 15 -10z m72 -27 c10 -9 18 -21 18 -27 0 -6 -8 -2 -16 9 -15 19 -16 18
-10 -15 5 -31 5 -32 -4 -11 -6 14 -9 30 -6 37 3 7 0 15 -7 18 -7 2 -8 5 -3 5
5 1 18 -6 28 -16z m-627 -13 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10
4 10 5 0 13 -4 16 -10z m40 -1 c11 -16 1 -19 -13 -3 -7 8 -8 14 -3 14 5 0 13
-5 16 -11z m619 -38 c3 -5 -1 -14 -8 -20 -12 -10 -13 -8 -9 8 6 23 9 25 17 12z
m-549 -51 c10 -11 16 -20 13 -20 -3 0 -15 9 -28 20 -13 11 -19 20 -13 20 5 0
18 -9 28 -20z m592 -72 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m-512 -8 c10 -11 16 -20 13 -20 -3 0 -13 9 -23 20 -10 11 -16 20 -13 20 3 0
13 -9 23 -20z m517 -45 l5 20 -1 -20 c-1 -29 -21 -25 -23 5 l-2 25 8 -25 c7
-23 8 -23 13 -5z m-424 -40 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7 10 -15 7
-18z m2 -39 c0 -5 -9 3 -20 17 -11 14 -20 29 -20 34 1 10 39 -39 40 -51z m417
-18 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-341 -25 c-7 -7
-26 7 -26 19 0 6 6 6 15 -2 9 -7 13 -15 11 -17z m-46 2 c0 -8 4 -15 9 -15 13
0 23 -32 12 -39 -5 -3 -11 -17 -14 -31 -2 -14 -3 -8 -2 12 2 20 -3 45 -11 55
-16 22 -18 33 -4 33 6 0 10 -7 10 -15z m34 -180 c5 -29 4 -36 -3 -25 -6 8 -11
28 -11 44 0 39 6 31 14 -19z m216 30 c0 -2 -10 -10 -22 -16 -21 -11 -22 -11
-9 4 13 16 31 23 31 12z m10 -29 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6
5 11 10 11 6 0 10 -2 10 -4z m-35 -16 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0
6 2 10 4 10 3 0 8 -4 11 -10z m-215 -10 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10
3 6 8 10 11 10 2 0 4 -4 4 -10z"/>
<path d="M3560 3649 c-14 -6 -81 -10 -150 -9 -69 1 -129 1 -135 1 -5 -1 6 -10
25 -22 19 -11 45 -32 58 -45 17 -18 30 -23 45 -19 17 5 19 4 7 -4 -13 -9 -12
-13 7 -26 11 -8 38 -39 59 -67 21 -29 41 -55 44 -58 5 -5 35 -65 65 -130 15
-34 16 -34 10 -5 -4 17 -6 37 -5 45 6 89 8 350 3 349 -5 0 -19 -5 -33 -10z
m-203 -25 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m153 -159 c0
-8 -2 -15 -4 -15 -2 0 -6 7 -10 15 -3 8 -1 15 4 15 6 0 10 -7 10 -15z m58 -90
c17 -38 14 -43 -8 -15 -21 27 -25 40 -12 40 5 0 14 -11 20 -25z"/>
<path d="M2065 3569 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M2103 3553 c15 -2 37 -2 50 0 12 2 0 4 -28 4 -27 0 -38 -2 -22 -4z"/>
<path d="M2105 3503 c-43 -10 -31 -18 25 -18 32 0 56 3 52 6 -11 10 -55 17
-77 12z"/>
<path d="M2201 3454 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2201 3419 c-1 -20 -29 -45 -56 -52 -19 -4 -19 -5 1 -6 29 -1 67 33
61 56 -4 14 -5 14 -6 2z"/>
<path d="M2077 3379 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M2060 3311 c0 -5 29 -9 65 -8 36 1 65 5 65 9 0 4 -29 8 -65 8 -36 0
-65 -4 -65 -9z"/>
<path d="M2200 3309 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2050 3235 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"/>
<path d="M2098 3203 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M2178 3203 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2050 3170 c0 -11 4 -20 9 -20 5 0 7 9 4 20 -3 11 -7 20 -9 20 -2 0
-4 -9 -4 -20z"/>
<path d="M2083 3103 c15 -2 39 -2 55 0 15 2 2 4 -28 4 -30 0 -43 -2 -27 -4z"/>
<path d="M2130 3000 l-55 -7 52 -1 c28 -1 55 3 58 8 3 6 5 9 3 8 -2 -1 -28 -4
-58 -8z"/>
<path d="M2426 2955 c4 -8 11 -15 16 -15 6 0 5 6 -2 15 -7 8 -14 15 -16 15 -2
0 -1 -7 2 -15z"/>
<path d="M2211 2904 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2140 2870 l-65 -7 68 -1 c39 -1 67 2 67 8 0 6 -1 9 -2 8 -2 -1 -32
-4 -68 -8z"/>
<path d="M2136 2811 c17 -4 37 -12 45 -16 12 -8 12 -6 0 8 -8 10 -26 17 -45
16 l-31 -1 31 -7z"/>
<path d="M2075 2799 c-11 -16 -1 -19 13 -3 7 8 8 14 3 14 -5 0 -13 -5 -16 -11z"/>
<path d="M2061 2734 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2105 2680 c3 -5 13 -10 21 -10 8 0 12 5 9 10 -3 6 -13 10 -21 10 -8
0 -12 -4 -9 -10z"/>
<path d="M2156 2632 c-2 -4 4 -9 15 -9 11 -1 17 2 14 8 -7 11 -22 12 -29 1z"/>
<path d="M2133 2595 c-3 -9 -3 -18 -1 -21 3 -3 8 4 11 16 6 23 -1 27 -10 5z"/>
<path d="M2116 2543 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M2526 2507 c-10 -14 -14 -16 -10 -4 9 25 -1 22 -65 -23 -59 -42 -107
-109 -97 -136 3 -8 2 -13 -3 -10 -13 8 -31 -41 -31 -83 0 -79 52 -185 130
-261 22 -22 43 -34 55 -32 15 2 16 1 7 -6 -14 -9 -6 -15 85 -65 35 -19 102
-45 148 -57 17 -4 39 -11 50 -16 11 -4 3 7 -18 26 -50 43 -60 69 -44 114 13
38 51 86 67 86 5 0 10 5 10 12 0 6 8 20 17 30 9 11 17 34 16 51 -1 18 1 23 4
13 4 -16 9 -12 24 20 19 40 24 138 6 127 -5 -3 -6 3 -3 15 4 11 2 23 -4 27 -5
3 -10 13 -10 21 0 26 -51 92 -93 121 -23 15 -45 32 -49 38 -4 5 -45 11 -91 12
-78 2 -86 1 -101 -20z m24 -37 c-24 -15 -37 -11 -16 5 11 8 22 12 25 9 3 -3
-1 -9 -9 -14z m210 -10 c0 -5 8 -10 17 -10 9 0 14 -3 10 -6 -8 -8 -47 5 -47
17 0 5 5 9 10 9 6 0 10 -4 10 -10z m60 -71 c0 -6 -4 -7 -10 -4 -5 3 -10 11
-10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m35 -68 c-9 -8 -25 21 -25 44 0 16 3
14 15 -9 9 -16 13 -32 10 -35z m-483 -33 c-6 -6 -7 0 -4 19 5 21 7 23 10 9 2
-10 0 -22 -6 -28z m458 22 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10
10 10 6 0 10 -4 10 -10z m27 -26 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17
-2 13 -5z m-498 -117 c0 -21 -1 -21 -9 -2 -12 28 -12 37 0 30 6 -3 10 -16 9
-28z m448 11 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-420 -30
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m435 -13 c3 -13 1 -22
-3 -19 -9 5 -13 44 -5 44 2 0 6 -11 8 -25z m-432 -31 c0 -3 -7 -1 -15 6 -8 7
-15 17 -15 22 0 5 7 3 15 -6 8 -8 15 -18 15 -22z m70 -86 c0 -6 -6 -5 -15 2
-8 7 -15 14 -15 16 0 2 7 1 15 -2 8 -4 15 -11 15 -16z m290 7 c0 -8 -4 -15
-10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-199 -74 c-11
-7 -9 -10 8 -16 12 -3 19 -11 15 -16 -4 -6 -13 -4 -25 7 -19 17 -17 34 4 34 8
0 7 -3 -2 -9z m84 -59 c-3 -3 -11 0 -18 7 -9 10 -8 11 6 5 10 -3 15 -9 12 -12z
m94 -31 c9 -17 8 -18 -11 -13 -14 3 -17 7 -9 10 11 4 11 6 0 13 -10 6 -10 9
-2 9 7 0 17 -9 22 -19z"/>
<path d="M2141 2303 c-8 -15 -12 -16 -22 -5 -34 33 -69 1 -69 -62 l0 -36 85 0
85 0 0 34 c0 55 -16 86 -44 86 -14 0 -29 -8 -35 -17z m57 -45 c3 -36 2 -38
-27 -38 -26 0 -31 4 -31 24 0 40 11 57 34 54 16 -2 22 -11 24 -40z m-80 -5 c3
-30 1 -33 -22 -33 -23 0 -26 4 -26 36 0 31 3 35 23 32 17 -2 23 -11 25 -35z"/>
<path d="M3959 2187 c-88 -33 -148 -88 -190 -173 -35 -71 -34 -178 3 -254 84
-175 276 -234 445 -139 125 71 184 232 136 372 -23 64 -92 141 -157 174 -62
32 -179 41 -237 20z"/>
<path d="M2050 2150 c0 -6 35 -10 86 -10 54 0 83 4 79 10 -3 6 -42 10 -86 10
-46 0 -79 -4 -79 -10z"/>
<path d="M2140 2085 c-9 -11 -16 -13 -24 -5 -31 31 -66 -3 -66 -64 l0 -36 85
0 85 0 0 41 c0 22 -5 49 -10 60 -13 23 -53 25 -70 4z m60 -45 c0 -38 -2 -40
-30 -40 -27 0 -30 3 -30 33 0 40 6 47 37 47 20 0 23 -5 23 -40z m-80 -5 c0
-31 -3 -35 -25 -35 -21 0 -25 5 -25 28 0 33 7 42 32 42 14 0 18 -8 18 -35z"/>
<path d="M2125 1921 c-38 -15 -70 -33 -70 -40 0 -11 131 -71 156 -71 20 0 8
18 -16 24 -21 5 -25 12 -25 46 0 22 4 40 8 40 5 0 17 7 28 15 30 23 -1 18 -81
-14z m27 -42 c-3 -23 -8 -29 -21 -23 -9 3 -24 9 -33 11 -26 8 -22 20 10 31 44
17 49 14 44 -19z"/>
<path d="M2054 1773 c8 -8 13 -10 46 -12 17 -1 20 -8 20 -45 0 -44 0 -45 -37
-49 -21 -2 3 -4 52 -4 50 0 71 2 48 4 l-43 4 0 45 c0 45 3 48 47 45 11 -1 24
3 28 8 4 8 -22 11 -81 11 -48 0 -84 -3 -80 -7z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
